import React, { useState, useEffect } from 'react';
import ButtonComponent from "../../Components/NextUi/Button";
import TableComponent from '../../Components/NextUi/Table';
import AvatarComponent from '../../Components/NextUi/Avatar';
import CreateChannel from '../Channels/CreateChannel';
import { useDisclosure, TableRow, TableCell, Tooltip } from "@nextui-org/react";
import { CommonCollectionFilterApi, DeleteSingleAttribute } from "../../Controllers/strapiController";
import { decriptedText, avatarName } from '../../Controllers/basicControllers';
import PaginationComponent from "../../Components/NextUi/Pagination";
import DeleteModal from "../../Components/BasicComponents/DeleteModal";
import { iconRender } from "../../Components/Navbar/items";
import { useParams } from "react-router-dom";
import CustomToast from '../../Components/BasicComponents/CustomToast';

const ApplicationChannel = ({ application, darkMode }) => {

    const headerComponent = ['Logo', "Channel Name", "Status", ""];
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { isOpen: isDelete, onOpen: onDelete, onOpenChange: onOpenDelete } = useDisclosure();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState();
    const [channel, setChannel] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteData, setDeleteData] = useState();
    const params = useParams();
    const appid = params?._appId;
    const decryptedId = decriptedText(appid)
    const [reload, setReload] = useState();

    // API RESPONSE RENDERING
    useEffect(() => {
        const url = `filters[app_id][$eq]=${decryptedId}&[populate][channel][on]`
        setLoading(true);
        CommonCollectionFilterApi("channel-responses", url)
            .then((data) => { setLoading(false); setChannel(data.data) })
            .catch((error) =>CustomToast("Something went wrong. Please try after some time."))
    }, [reload])

    const deleteOnchange = (data, e) => {
        e.stopPropagation();
        onDelete();
        setDeleteData(data);
    }

    const DeleteHandler = async () => {
        const response = await DeleteSingleAttribute("channel-responses", deleteData.id)
        if (response) {
            setReload(response);
        }
    }

    const recordsPerPage = 10;

    // Calculate indexes for pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = channel && channel.slice(indexOfFirstRecord, indexOfLastRecord);


    const tableBodyComponet = () => {
        return currentRecords?.map((data, index) => {
            
            return <TableRow key={`${index}`} onClick={() => { }} className='cursor-pointer'>
                <TableCell><AvatarComponent src={data?.attributes?.channel?.data?.attributes?.Logo !== null ? data?.attributes?.channel?.data?.attributes?.Logo : ''} name={avatarName(data?.attributes?.channel?.data?.attributes?.Name)} /></TableCell>
                <TableCell>{data?.attributes?.channel?.data?.attributes?.Name}</TableCell>
                <TableCell>{data?.attributes?.active ? "true" : "false"}</TableCell>
                <TableCell className='flex justify-end'>
                    <div onClick={(e) => deleteOnchange(data, e)}>
                        <Tooltip content='Delete' placement='left'>
                            {iconRender(darkMode, "https://cdn.lordicon.com/wpyrrmcq.json", "Delete")}
                        </Tooltip>
                    </div>
                </TableCell>
            </TableRow>
        })
    }

    return (
        <div>
            <div className='flex justify-end'>
                <ButtonComponent title='Create an Channel' size='sm' color='secondary' func={() => { onOpen(); setFormData({}); setStep(1) }} />
            </div>
            <div className='pt-8'>
                <TableComponent headerComponent={headerComponent} bodyComponent={tableBodyComponet} emptyContent={"Channel Data is empty. Click on Create new channel to create your first channel"} />
            </div>
            <CreateChannel isOpen={isOpen} onOpenChange={onOpenChange} appId={application?.id} setFormData={setFormData} formData={formData} setStep={setStep} step={step} setReload={setReload} />
            {channel?.length > 10 && <div className='flex justify-end pt-8'>
                <PaginationComponent
                    color={'secondary'}
                    totalPage={channel}
                    currentRecords={currentRecords}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage}
                />
            </div>}

            <DeleteModal
                isOpen={isDelete}
                onOpenChange={onOpenDelete}
                Collectionname={deleteData?.attributes?.Name}
                setLoader={""}
                DeleteHandler={DeleteHandler} />
        </div>
    )
}

export default ApplicationChannel