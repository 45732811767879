// src/reducers.js
import { SET_DATA, setUserData } from './actions';

const initialState = {
    data: null,
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                data: action.payload,
            };

            case setUserData:
                return {
                    ...state,
                    userData:action.payload
                }
        default:
            return state;
    }


};

export default rootReducer;
