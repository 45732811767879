import React from 'react';
import {Select} from "@nextui-org/react";

const SelectComponent = ({selectItems,placeholder,label,defaultSelectedKeys,className,commonProps,handleChange,name,errorMessage,isInvalid}) => {
  return (
    <Select
    {...commonProps}
    label={label}
    placeholder={placeholder}
    defaultSelectedKeys={defaultSelectedKeys}
    className={className}
    onChange={handleChange}
    name={name}
    errorMessage={errorMessage}
    isInvalid={isInvalid}
    scrollShadowProps={{
      isEnabled: false
    }}
  >
    {selectItems()}
    </Select>
  )
}

export default SelectComponent;