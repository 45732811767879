import React, { useState, useEffect } from 'react';
import { singleCollectionFilter,getOneData, userLoginDetails } from "../../Controllers/strapiController";
import { decriptedText, avatarName, colorPicker } from "../../Controllers/basicControllers";
 
import { useParams } from "react-router-dom";
import NotFound from '../../Components/ErrorHandler/NotFound';
import ServerIssue from '../../Components/ErrorHandler/ServerIssue';
import Forbbiden from '../../Components/ErrorHandler/Forbbiden';
import UnAuthorized from '../../Components/ErrorHandler/UnAuthorized';
import Loading from '../../Components/ErrorHandler/Loading';
import AvatarComponent from '../../Components/NextUi/Avatar';
import { Tab } from "@nextui-org/react";
import TabsComponent from '../../Components/NextUi/Tabs';
import TabsJson from './TabsJson';
import CustomToast from '../../Components/BasicComponents/CustomToast';

const SingleRollout = ({ darkMode }) => {
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [loader, setLoader] = useState(true);
    const [reload, setReload] = useState(true);
    const [rollout, setRollout] = useState([]);
    const userId = userLoginDetails()?.userId;
    const params = useParams();
    const id = decriptedText(params?._rollId);
    const tabs = rollout ? TabsJson({ darkMode, rollout, setReload }) : null;


    useEffect(() => {
        const url = `filters[author][$eq]=${userId}&sort[0]=createdAt:desc&[populate][survey_schema][on]`;
        singleCollectionFilter("rollouts", id, url)
            .then((data) => {
                if (data?.error) {
                    setLoader(false);
                    if (data?.error?.status === 404) { setNotFound(true) }
                    else if (data?.error?.status === 403) { setForbidden(true) }
                    else if (data?.error?.status === 500) { setServerIssue(true) }
                    else if (data?.error?.status === 401) { setAuthorized(true) }
                    else {
                        CustomToast("Something went wrong with collections, please try after some time.")
                    }
                }
                else if (data?.data) {
                    setRollout(data?.data);
                    setLoader(false);
                }
            })
            .catch((error) => { setServerIssue(true); setLoader(false); })
    }, [reload])

    const tabsComponent = () => {
        return tabs?.map((item) => (
            <Tab key={item.id} title={item.label}>
                {item.content}
            </Tab>
        ))
    }

    if (notFound) { return <NotFound /> }
    if (serverIssue) { return <ServerIssue /> }
    if (forbidden) { return <Forbbiden /> }
    if (authorized) { return <UnAuthorized /> }
    if (loader) { return <Loading darkMode={darkMode} /> }


    return (
        <div>
            <div className='flex flex-row gap-4 items-center'>
                <AvatarComponent name={avatarName(rollout?.attributes?.Name)} color={colorPicker(rollout?.id)} radius='sm' />
                <p className='text-xl font-normal'>{rollout?.attributes?.Name}</p>
            </div>
            <div className='pt-8 pb-8 text-sm'>
                {rollout?.attributes?.Description}
            </div>
            <TabsComponent tabsComponent={tabsComponent} tabs={tabs} variant={"underlined"} color={colorPicker(rollout?.id)} />
        </div>
    )
}

export default SingleRollout;