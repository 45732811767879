import React from 'react'
import { iconRender } from '../Navbar/items';

const Header = ({darkMode}) => {
  return (
    <div className='border-b p-4 border-divider flex flex-row justify-end'>
      {iconRender(darkMode,'https://cdn.lordicon.com/vspbqszr.json','Header')}
    </div>
  )
}

export default Header;