// src/actions.js
export const SET_DATA = 'SET_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';

export const setData = (data) => ({
    type: SET_DATA,
    payload: data,
});

export const setUserData = (data) => ({
    type: SET_DATA,
    payload: data,
});


