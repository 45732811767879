import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Switch, Textarea, Select, Slider, DatePicker, SelectItem, Button, Spinner, TableCell, TableRow, Table, TableHeader, TableColumn, TableBody } from '@nextui-org/react';
import { DateChanger, DateFormat, decriptedText } from '../../Controllers/basicControllers';
import { CommonCollectionFilterApi, CreateData, getOneData, uploadFile } from '../../Controllers/strapiController';
import { Toaster } from "react-hot-toast";
import NotFound from '../../Components/ErrorHandler/NotFound';
import ServerIssue from '../../Components/ErrorHandler/ServerIssue';
import Forbbiden from '../../Components/ErrorHandler/Forbbiden';
import UnAuthorized from '../../Components/ErrorHandler/UnAuthorized';
import Titlecomponent from "../../Components/BasicComponents/Titlecomponent";
import { EyeSlashFilledIcon } from '../../Images/EyeSlashFilledIcon';
import { EyeFilledIcon } from '../../Images/EyeFilledIcon';
import ButtonSpinner from '../../Components/ErrorHandler/ButtonSpinner';
import { parseDate } from "@internationalized/date";
import FunctionLoader from '../../Components/ErrorHandler/FunctionLoader';
import CustomToast from '../../Components/BasicComponents/CustomToast';
import SuccessPage from '../../Components/ErrorHandler/SuccessPage';
import SubmissionPage from '../../Components/ErrorHandler/SubmissionPage';

const SurveyForm = ({ darkMode, surveySchema }) => {
  // STATE INITIALIZATION
  const params = useParams();
  const [serveyForm, setServeyForm] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [userAuthorization, setUserAuthorization] = useState(false);
  const [submission, setSubmission] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [validation, setValidation] = useState();
  const [validateCondition, setValidateCondition] = useState();
  const [upload, setUpload] = useState(false);

  const fileInputRef = useRef(null);
  // FILE UPLOAD REFERENCE REF
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // HANDLE CHANGE
  const handleChange = (field, value) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  // SUPPORTING VALUES
  const formId = decriptedText(params?._id);
  const email = decriptedText(params?._email);
  const time = params?._time;
  const datevalidate = decriptedText(time)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // PASSWORD EYE FUNCTIONALITY
  const toggleVisibility = () => setIsVisible(!isVisible);

  // API RESPONSE
  useEffect(() => {
    const id = formId ? formId : surveySchema?.attributes?.metric_id;
    if (id) {
      getOneData('survey-schemas', id)
        .then((data) => {
          if (data?.error) {
            if (data?.error?.status === 404) { setNotFound(true) }
            else if (data?.error?.status === 403) { setForbidden(true) }
            else if (data?.error?.status === 500) { setServerIssue(true) }
            else if (data?.error?.status === 401) { setAuthorized(true) }
            else {
              CustomToast("Something went wrong with collections, please try after some time.")
            }
          }
          else if (data?.data) {
            setServeyForm(data?.data)
            setLoader(false)
          }
          else { setLoader(false) }
        })
        .catch((error) => { setLoader(false); CustomToast("Something went wrong with collections, please try after some time.") })
    } else {
      setLoader(false)
    }
  }, [formId, email, surveySchema]);

  useEffect(() => {
    if (datevalidate !== '' || datevalidate !== null) {
      if (!surveySchema && datevalidate) {
        const url = `filters[Timestamp][$eq]=${datevalidate}&filters[metric_id][$eq]=${formId}&filters[email][$eq]=${email}`
        CommonCollectionFilterApi('response-jsons', url)
          .then((data) => {
            if (data?.data?.length > 0) {
              setUserAuthorization(true)
            }
          })
      }
    }
  }, [datevalidate])

  // Initialize form values
  useEffect(() => {
    if (serveyForm && !surveySchema) {
      const initialValues = {};
      Object.keys(serveyForm?.attributes?.JSONSchema?.properties).forEach(fieldKey => {
        const fieldConfig = serveyForm?.attributes?.JSONSchema?.properties[fieldKey];
        initialValues[fieldKey] = fieldConfig.defaultValue || '';
      });
      setFormValues(initialValues);
    }

    if (surveySchema && serveyForm) {
      const initialValues = {};
      Object.keys(serveyForm?.attributes?.JSONSchema?.properties).forEach(fieldKey => {
        const schemaValue = surveySchema?.attributes?.ResponseJson?.properties?.[fieldKey];
        initialValues[fieldKey] = schemaValue !== undefined ? schemaValue : '';
      });
      setFormValues(initialValues);
    }
  }, [serveyForm, surveySchema]);

  const UploadFileHandler = async (field, file) => {
    setUpload(true);
    const response = await uploadFile(file);
    if (response) {
      setUpload(false);
      const URLresponse = response && response[0];
      setFormValues({
        ...formValues,
        [field]: URLresponse,
      });
    }
  };

  const renderFieldsById = () => {
    const fieldEntries = Object.entries(serveyForm?.attributes?.JSONSchema?.properties || {});
    fieldEntries.sort(([, fieldA], [, fieldB]) => fieldA.id - fieldB.id);
    return fieldEntries.map(([fieldKey, fieldConfig]) => (
      <div key={fieldKey} className="flex flex-col mb-9">
        {renderField(fieldKey, fieldConfig)}
      </div>
    ));
  };

  // VALIDATION FORM
  const validateField = (fieldKey, fieldConfig, value) => {
    let errorMessage = '';

    switch (fieldConfig.fieldType) {
      case 'input':
        if (fieldConfig.required && !value) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        break;

      case 'textarea':
        if (fieldConfig.required && !value) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        if (fieldConfig.charLimit && value?.length > fieldConfig.charLimit) {
          errorMessage = `${fieldConfig.title} exceeds the character limit of ${fieldConfig.charLimit}.`;
        }
        break;

      case 'boolean':
        if (fieldConfig.required && value === undefined) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        break;

      case 'integer':
        if (fieldConfig.required && (value === undefined || value === '')) {
          errorMessage = `${fieldConfig.tsitle} is required.`;
        }
        break;

      case 'dropdown':
        if (fieldConfig.required && !value) {
          errorMessage = `Please select a value for ${fieldConfig.title}.`;
        }
        break;

      case 'radio':
        if (fieldConfig.required && !value) {
          errorMessage = `Please rate a value for ${fieldConfig.title}.`;
        }
        break;

      case 'date':
        if (fieldConfig.required && !value) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        // You can add further date validations here if needed
        break;

      case 'slider':
        if (fieldConfig.required && (value === undefined || value === null)) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        break;

      case 'password':
        if (fieldConfig.required && !value) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        if (fieldConfig.minLength && value.length < fieldConfig.minLength) {
          errorMessage = `${fieldConfig.title} should be at least ${fieldConfig.minLength} characters long.`;
        }
        if (fieldConfig.regex && !new RegExp(fieldConfig.regex).test(value)) {
          errorMessage = `${fieldConfig.title} is not in the correct format.`;
        }
        break;

      case 'file':
        if (fieldConfig.required && !value) {
          errorMessage = `Please upload a file for ${fieldConfig.title}.`;
        }
        if (fieldConfig.fileAccepted && value && fieldConfig.fileAccepted?.toLowerCase() !== value?.ext?.slice(1)) {
          errorMessage = `The file type for ${fieldConfig.title} must be one of the following: ${fieldConfig.fileAccepted}.`;
        }
        break;

      default:
        break;
    }

    return errorMessage;
  };

  // SUBMIT HANDLER
  const submitHandler = async () => {
    let isValid = true;
    let validationErrors = {};

    Object.keys(serveyForm?.attributes?.JSONSchema?.properties).forEach(fieldKey => {
      const fieldConfig = serveyForm?.attributes?.JSONSchema?.properties[fieldKey];
      const value = formValues[fieldKey];

      const errorMessage = validateField(fieldKey, fieldConfig, value);
      if (errorMessage) {
        isValid = false;
        validationErrors[fieldKey] = errorMessage;
      }
    });

    if (!isValid) {
      setValidation(validationErrors)
      setValidateCondition(true);
    } else {
      setButtonLoader(true);
      setValidateCondition(false);
      const payload = {
        ResponseJson: {
          surveyTitle: serveyForm?.attributes?.Name,
          surveyDescription: serveyForm?.attributes?.Description,
          properties: formValues
        },
        Timestamp: decriptedText(time),
        metric_id: formId,
        email: email
      }

      const response = await CreateData('response-jsons', payload);

      if (response) {
        setButtonLoader(false);
        CustomToast("Form submitted successfully.");
        setSubmission(true)
      }
    }
  };

  const tableBodyComponent = (fieldConfig, fieldKey) => {
    return (
      <TableRow onClick={() => { }} key={fieldKey} className={`${surveySchema ? "cursor-not-allowed" : "cursor-pointer"}`}>
        <TableCell className='text-start'>{fieldConfig?.title}</TableCell>
        {fieldConfig?.options?.map((data, index) => (
          <TableCell key={index}>
            <label className={`flex items-center justify-evenly ${surveySchema ? "cursor-not-allowed" : "cursor-pointer"}`}>
              <input
                name={`radio-${fieldKey}`}
                type="radio"
                value={!surveySchema && data}
                checked={formValues[fieldKey] === data}
                onChange={!surveySchema ? (e) => handleChange(fieldKey, e.target.value) : () => { }}
                className="hidden peer"
              />
              <span className="w-4 h-4 rounded-full border-1 border-gray-400 bg-gray-100 transition-all duration-200 peer-checked:border-transparent peer-checked:bg-secondary peer-checked:text-secondary"></span>
            </label>
          </TableCell>
        ))}
      </TableRow>
    );
  };


  // DYNAMICALLY FORM RENDERING
  const renderField = (fieldKey, fieldConfig) => {
    const errorMessage = validation?.[fieldKey];
    const disabled = surveySchema;
    const radioOptions = fieldConfig?.options?.map((data, index) => data)
    const headerOptions = [" "].concat(radioOptions)

    const commonProps = {
      label: fieldConfig.title,
      isRequired: fieldConfig?.required,
      value: formValues[fieldKey] || '',
      placeholder: `Enter ${fieldConfig.title}`,
      labelPlacement: 'outside',
      isDisabled: disabled ? true : false,
      errorMessage: validateCondition && formValues[fieldConfig.title] === '' && errorMessage,
      isInvalid: validateCondition && formValues[fieldConfig.title] === '' && !!errorMessage,
      onChange: (e) => handleChange(fieldKey, e.target.value),
    };

    switch (fieldConfig.fieldType) {
      case 'input':
        return <Input {...commonProps} />;
      case 'textarea':
        return <Textarea {...commonProps} maxLength={fieldConfig.charLimit} />;
      case 'boolean':
        return <div>
          <p className={`text-sm ${disabled ? "text-default-400" : ""}`}> {fieldConfig.title} {fieldConfig.required && <span className='text-danger'>*</span>}</p>
          <Switch isDisabled={disabled ? true : false} isSelected={formValues[fieldKey]} errorMessage={errorMessage} isInvalid={errorMessage !== null} size='md' color='secondary' isRequired={fieldConfig?.required} onChange={(e) => handleChange(fieldKey, e.target.checked)} />
        </div>
      case 'integer':
        return <Input type="number" {...commonProps} min={fieldConfig.minValue} max={fieldConfig.maxValue} />;
      case 'dropdown':
        return (
          <Select
            label={fieldConfig.title}
            selectionMode={fieldConfig.multiSelect ? 'multiple' : 'single'}
            labelPlacement='outside'
            isDisabled={disabled ? true : false}
            selectedKeys={formValues[fieldKey]?.split(',')}
            errorMessage={validateCondition && formValues[fieldConfig.title] === '' && errorMessage}
            isInvalid={validateCondition && formValues[fieldConfig.title] === '' && !!errorMessage}
            isRequired={fieldConfig?.required}
            placeholder={`Select option`
            }
            onChange={(e) => handleChange(fieldKey, e.target.value)}
          >
            {
              fieldConfig.options.map(option => (
                <SelectItem key={option} value={option}>{option}</SelectItem>
              ))
            }
          </Select >
        );
      case 'date':
        return <DatePicker
          label={fieldConfig.title}
          labelPlacement='outside'
          isDisabled={disabled ? true : false}
          errorMessage={validateCondition && formValues[fieldConfig.title] === '' && errorMessage}
          isInvalid={validateCondition && formValues[fieldConfig.title] === '' && !!errorMessage}
          placeholder={`Enter ${fieldConfig.title}`}
          isRequired={fieldConfig?.required}
          value={
            formValues[fieldKey] && formValues[fieldKey] !== "" && formValues[fieldKey] !== "null"
              ? parseDate(DateChanger(formValues[fieldKey]))
              : null
          }
          onChange={(e) => {
            const displayDate = DateFormat(e)
            handleChange(fieldKey, displayDate)
          }}
        />
      case 'slider':
        return (
          <Slider
            {...commonProps}
            max={fieldConfig.maxValue}
            min={fieldConfig.minValue}
            color='secondary'
            onChange={(value) => handleChange(fieldKey, value)}
          />
        );
      case 'password':
        return <Input  {...commonProps}
          endContent={
            <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
              {isVisible ? (
                <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
              ) : (
                <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
              )}
            </button>
          }
          type={isVisible ? "text" : "password"}
        />;
      case 'radio':
        return <div><Table disabledKeys={surveySchema ? [fieldKey] : ''} removeWrapper aria-label="Example static collection table">
          <TableHeader>
            {headerOptions?.map((data, index) => (
              <TableColumn key={`${index}`} align="center">{data}</TableColumn>
            ))}
          </TableHeader>
          <TableBody>
            {tableBodyComponent(fieldConfig, fieldKey)}
          </TableBody>
        </Table>
          {errorMessage !== null && <p className='text-xs text-danger'>{errorMessage}</p>}
        </div>
      case 'file':
        return <>
          <input
            type='file'
            accept={`application/${fieldConfig.fileAccepted}`}
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => {
              const files = e.target.files;

              if (files && files[0]) {
                const file = files[0];
                UploadFileHandler(fieldKey, file)
              }
            }}
          />
          <div className='flex flex-col gap-2 '>
            <p className={`text-sm ${disabled ? "text-default-400" : ""} ${validateCondition && fieldConfig.required && formValues[fieldConfig.title] === '' ? 'text-danger' : "default"}`}>{fieldConfig.title}{fieldConfig.required && <span className='text-danger'>*</span>}</p>
            <div className='flex flex-row gap-2 items-center'>
              <Button isDisabled={disabled ? true : false} variant='bordered' size='sm' onClick={handleButtonClick}>
                Select file
              </Button>
              {upload ? <Spinner /> :
                <p className='text-sm text-default-400'>{formValues?.[fieldConfig.title]?.name}</p>
              }
            </div>
          </div>
          {fieldConfig.fileAccepted !== formValues?.[fieldConfig.title]?.type?.split('/')?.[1] && errorMessage !== null && <p className='text-xs text-danger'>{errorMessage}</p>}
        </>;
      default:
        return null;
    }
  };

  // SUPPORTING RESPONSES
  if (notFound) { return <NotFound /> }
  if (serverIssue) { return <ServerIssue /> }
  if (forbidden) { return <Forbbiden /> }
  if (authorized) { return <UnAuthorized /> }
  if (userAuthorization) { return <SuccessPage /> }
  if (submission) { return <SubmissionPage /> }
  if (loader) { return <FunctionLoader darkMode={darkMode} /> }
  if (!surveySchema) {
    if ((!emailRegex.test(email)) || !datevalidate || !formId) {
      return <NotFound />
    }
  }

  // RETURN UI
  return (
    <div className={`max-w-6xl mx-auto pb-8 px-4 ${!surveySchema ? "pt-8" : ""} `}>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
      {!surveySchema && <Titlecomponent title={serveyForm?.attributes?.Name} description={serveyForm?.attributes?.Description} />}
      <form className='pt-8 md:w-1/2 sm:w-full'>
        <div className=''>
          {renderFieldsById()}
        </div>
        {!surveySchema && <div className=' flex justify-end'>
          <Button isLoading={buttonLoader} spinner={<ButtonSpinner />} color='secondary' variant='faded' onClick={() => submitHandler()} size='sm'>Submit</Button>
        </div>}
      </form>
    </div>
  )
}

export default SurveyForm;