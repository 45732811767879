import React, { useState, useEffect } from 'react';
import { ScrollShadow, Card, CardHeader, CardBody, Divider, useDisclosure } from "@nextui-org/react";
import { CommonCollectionFilterApi } from "../../Controllers/strapiController";
import { avatarName, colorPicker, encryptedText, StringLimit } from '../../Controllers/basicControllers';
import Titlecomponent from '../../Components/BasicComponents/Titlecomponent';
import ButtonComponent from '../../Components/NextUi/Button';
import Loading from "../../Components/ErrorHandler/Loading";
import { useNavigate } from "react-router-dom";
import AvatarComponent from '../../Components/NextUi/Avatar';
import CreateApp from './CreateApp';
import imagerender from '../../Components/BasicComponents/imagerender';
import CustomToast from '../../Components/BasicComponents/CustomToast';

const Applications = ({ darkMode }) => {
  // STATE INITIALIZATION
  const [application, setApplication] = useState();
  const [loading, setLoading] = useState(true);
  const [reload,setReloader]=useState(null);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const userDetails = JSON.parse(sessionStorage.getItem("userData"))
  const userID = userDetails && userDetails.user && userDetails.user.id;
  const navigate = useNavigate();

  // API RESPONSE RENDERING
  useEffect(() => {
    setLoading(true);
    const url=`filters[author][id][$eq]=${userID}&[populate][Logo][on]`
    CommonCollectionFilterApi("applications",url)
      .then((data) => { setLoading(false); setApplication(data.data) })
      .catch((error) => CustomToast("Something went wrong. Please try after sometime."))
  }, [userID,reload])



  // SELECT HANDLER TO NAVIGATE TO PERTICULAR PAGE
  const handleSelected = async (data) => {
    const encryptedID = encryptedText(`${data.id}`)
    navigate(`${encryptedID}`)
  }

  if (loading) { return <Loading darkMode={darkMode} /> }

  return (
    <div className='pt-2'>
      <Titlecomponent title={"Application"} description={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed provident, assumenda id reprehenderit aliquam quasi.assumenda id reprehenderit aliquam quasi. Sed explicabo nam modi voluptate."} />
      <div className='flex justify-end'>
        <ButtonComponent title='+ Create New Application' size='sm' varient='flat' color='danger' func={() => { onOpen() }} />
      </div>
        <div className='grid pt-8 md:grid-cols-2 gap-12 sm:grid-cols-1 lg:grid-cols-3'>
          {application && application.map((data, index) => {
            
            return <div key={`${index}`} onClick={() => handleSelected(data)} className='w-full cursor-pointer'>
              <Card className="max-w-[400px] h-full border border-divider" shadow='none'>
                <CardHeader className="flex gap-3">
                  <AvatarComponent
                    alt="nextui logo"
                    height={40}
                    radius="sm"
                    size=''
                    name={avatarName(data?.attributes?.Name)}
                    color={colorPicker(data?.id)}
                    width={40}
                    src={data?.attributes?.Logo?.data!==null?imagerender(data?.attributes?.Logo?.data?.attributes?.url):''}
                  />
                  <div className="flex flex-col">
                    <p className="text-md">{data?.attributes?.Name}</p>
                  </div>
                </CardHeader>
                <Divider />
                <CardBody>
                  <div className='flex items-end justify-start gap-2 min-h-[50px]'>
                    {data?.attributes?.Description!==null&&StringLimit(data?.attributes?.Description, 90)}
                  </div>
                </CardBody>
              </Card>
            </div>
          })}
        </div>
      <CreateApp
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        setReloader={setReloader} />
    </div>
  )
}

export default Applications