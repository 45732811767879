import React, { useState, useEffect } from 'react';
import { Avatar, Tabs, Tab } from "@nextui-org/react";
import ApplicationChannel from './ApplicationChannel';
import { useParams } from "react-router-dom";
import { avatarName, colorPicker, decriptedText } from '../../Controllers/basicControllers';
import { getOneData } from '../../Controllers/strapiController';
import AvatarComponent from '../../Components/NextUi/Avatar';
import imagerender from '../../Components/BasicComponents/imagerender';
import UserSettings from '../Settings/userSettings';
import AppSettings from './App_Settings';
import {toast} from "react-hot-toast";
import Loading from '../../Components/ErrorHandler/Loading';
import CustomToast from '../../Components/BasicComponents/CustomToast';

const SingleApplication = ({darkMode}) => {
    const [selected, setSelected] = useState("Channels");
    const [loader,setLoader]=useState(true);
    const [application,setApplication] = useState(null);
    const params = useParams();
    const appId = params?._appId;
    const singleApp=decriptedText(appId);

    useEffect(() => {
        setLoader(true);
     getOneData("applications", singleApp)
     .then((data)=>{setApplication(data.data);setLoader(false)})
     .catch((error)=>{
        setLoader(false);
        CustomToast("Something went wrong. Please try after sometime.");
     })
    }, [appId])

    if (loader) { return <Loading darkMode={darkMode} /> }
    
    return (
        <div>
            <div className='flex flex-row gap-4 items-center'>
                <AvatarComponent src={application?.attributes?.Logo?.data!==null?imagerender(application?.attributes?.Logo?.data?.attributes?.url):''} name={avatarName(application?.attributes?.Name)} color={colorPicker(application?.id)} radius='sm' />
                <p className='text-xl font-normal'>{application?.attributes?.Name}</p>
            </div>
            <div className='pt-8 pb-8'>
               {application?.attributes?.Description}
            </div>
            <Tabs
                aria-label="Options"
                selectedKey={selected}
                variant={"underlined"}
                color={colorPicker(application?.id)}
                onSelectionChange={setSelected}>
                <Tab key="Channels" title="Channels">
                    <ApplicationChannel application={application} darkMode={darkMode}/>
                </Tab>
                <Tab key="Settings" title="Settings">
                    <AppSettings application={application} darkMode={darkMode}/>
                </Tab>
            </Tabs>
        </div>
    )
}

export default SingleApplication;