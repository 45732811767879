import React, { useState, useEffect } from 'react'
import Titlecomponent from '../../Components/BasicComponents/Titlecomponent';
import { Input, Textarea } from "@nextui-org/react";
import { singleCollectionFilter, userLoginDetails } from "../../Controllers/strapiController";
import SchemaBuild from './SchemaBuild';
import { useParams } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { decriptedText } from '../../Controllers/basicControllers';
import NotFound from '../../Components/ErrorHandler/NotFound';
import ServerIssue from '../../Components/ErrorHandler/ServerIssue';
import Forbbiden from '../../Components/ErrorHandler/Forbbiden';
import UnAuthorized from '../../Components/ErrorHandler/UnAuthorized';
import Loading from '../../Components/ErrorHandler/Loading';
import CustomToast from '../../Components/BasicComponents/CustomToast';

const CreateSurvey = ({ darkMode, setReload }) => {
    const [jsonSchema, setJsonSchema] = useState({ Name: '', Description: '', properties: {} });
    const [errorMessage, setErrorMessage] = useState({});
    const [validattion, setValidation] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [loader, setLoader] = useState(true);
    const params = useParams();
    const userId = userLoginDetails()?.userId;
    const description = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse, voluptasn t consectetur adipisicing elit. Esse, voluptas.."

    // API RESPONSE RENDERING 
    const id = decriptedText(params?._id);
    useEffect(() => {
        if (params?._id) {
            const url = `filters[author][id][$eq]=${userId}&sort[0]=createdAt:desc&[populate][author][on]`;

            singleCollectionFilter('survey-schemas', id, url)
                .then((data) => {
                    if (data?.error) {
                        if (data?.error?.status === 404) { setNotFound(true) }
                        else if (data?.error?.status === 403) { setForbidden(true) }
                        else if (data?.error?.status === 500) { setServerIssue(true) }
                        else if (data?.error?.status === 401) { setAuthorized(true) }
                        else {
                            CustomToast("Something went wrong with collections, please try after some time.")
                        }
                    }
                    else if (data?.data) {
                       
                        setJsonSchema({
                            Name: data?.data?.attributes?.Name,
                            Description: data?.data?.attributes?.Description,
                            properties: data?.data?.attributes?.JSONSchema?.properties,
                        })
                        setLoader(false)
                    }
                    else { setLoader(false) }
                })
                .catch((error) => CustomToast("Something went wrong with collections, please try after some time."))
        } else {
            setLoader(false);
        }
    }, [params?._id])


    if (notFound) { return <NotFound /> }
    if (serverIssue) { return <ServerIssue /> }
    if (forbidden) { return <Forbbiden /> }
    if (authorized) { return <UnAuthorized /> }
    if (loader) { return <Loading darkMode={darkMode} /> }

    return (
        <div>
            <Titlecomponent title="Survey" description={description} />
            <div className='flex flex-col gap-6 w-1/2 pt-8'>
                <Input
                    type="email"
                    variant="faded"
                    label="Survey name"
                    placeholder='enter survey name'
                    labelPlacement='outside'
                    isRequired
                    value={jsonSchema.Name}
                    onChange={(e) => setJsonSchema(prev => ({ ...prev, Name: e.target.value }))}
                    errorMessage={validattion && jsonSchema.Name === '' ? errorMessage.Name : ''}
                    isInvalid={validattion && jsonSchema.Name === ''}
                    className="col-span-12 md:col-span-6 mb-6 md:mb-0" />
                <Textarea
                    variant='faded'
                    label="Survey description"
                    labelPlacement="outside"
                    placeholder="Enter your description"
                    isRequired
                    value={jsonSchema.Description}
                    isInvalid={validattion && jsonSchema.Description === ''}
                    onChange={(e) => setJsonSchema(prev => ({ ...prev, Description: e.target.value }))}
                    errorMessage={validattion && jsonSchema.Description === '' ? errorMessage.Description : ''}
                    maxRows={10}
                    minRows={4}
                    className="col-span-12 md:col-span-6 mb-6 md:mb-0"
                />
                <SchemaBuild
                    darkMode={darkMode}
                    setJsonSchema={setJsonSchema}
                    jsonSchema={jsonSchema}
                    setErrorMessage={setErrorMessage}
                    setValidation={setValidation}
                    validattion={validattion}
                    errorMessage={errorMessage}
                    params={id}
                    setReload={setReload} />
            </div>
        </div>
    )
}

export default CreateSurvey;